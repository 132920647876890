import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/Layout'


class PromotionPageTemplate extends React.Component {
  render() {
    const promotionPage = get(this.props, 'data.contentfulPromotionalPage')

    return (
      <Layout pageData={promotionPage}></Layout>
    )
  }
}

export default PromotionPageTemplate

export const pageQuery = graphql`
  query PromotionPagePerLocale(
    $countryCode: String!
    $languageCode: String!
    $slug: String
  ) {
    contentfulPromotionalPage(
      countryCode: { eq: $countryCode }
      languageCode: { eq: $languageCode }
      slug: { eq: $slug }
    ) {
      metaAuthor
      metaTitle
      metaDescription
      metaKeywords
      slug
      appleStoreImage {
        url
        description
      }
      appleStoreLink
      countryCode
      countryName
      downloadAndSaveSubtitle
      downloadAndSaveTitle
      downloadTheFreeAppText
      downloadAndSaveImage {
        url
        description
      }
      downloadAndSaveVideo {
        url
        file {
          url
        }
      }
      faqs {
        id
        title
        content {
          raw
        }
        response {
          id
          content {
            content
          }
        }
      }
      faqsTitle
      findACarWashText
      findACarWashLink
      findYourNearestImage {
        url
        description
      }
      findYourNearestButtonLink
      findYourNearestButtonText
      findYourNearestSubtitle
      findYourNearestTitle
      footerLink {
        title
        url
        id
        displayedName
      }
      googlePlayImage {
        url
        description
      }
      googlePlayLink
      headerLogo {
        url
        description
      }
      imoCarWashCompanyName
      keepUpWithButtonText
      keepUpWithButtonLink
      keepUpWithHeading
      keepUpWithBackgroundImage {
        url
        description
      }
      keepUpWithBackgroundImageMobile {
        url
        description
      }
      languageCode
      loveYourCarImage1 {
        url
        description
      }
      loveYourCarImage2 {
        url
        description
      }
      loveYourCarImage3 {
        url
        description
      }
      loveYourCarSubtitle1
      loveYourCarSubtitle2
      loveYourCarSubtitle3
      loveYourCarTitle
      loveYourCarTitle1
      loveYourCarTitle2
      loveYourCarTitle3
      saveTimeSaveMoneyText
      useMyLocationText
      yourNearestImoCarWashSites
      getDirectionsText
      moreDetailsText
      servicesText
    }
  }
`